import {
    createBrowserRouter
  } from "react-router-dom";
import App from './App';

  const router = createBrowserRouter([
    {
      path: "/",
      element: <App/>
    },
    {
        path: "esw/jsf/secure/shared/common/QRCode.xhtml",
        element: <App />,
    },
  ]);

  export default router;