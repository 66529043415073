import { useState } from 'react';
import './App.css';
import Header from './components/Header';
import PDFViewDefault from './components/Viewer';

function App() {
  const [lang, setLang] = useState('en');
  return (
    <div className="App">
      {lang === 'en'? <Header.Header onChange={setLang}/>: <Header.HeaderArab onChange={setLang}/> }
      <div className='page-container container'>
        {lang === 'en'? <PDFViewDefault.PDFViewDefault/>: <PDFViewDefault.PDFViewDefaultArab/>}
      </div>
    </div>
  );
}

export default App;
